import React, { useState, useContext } from 'react'
import loadable from '@loadable/component'
import styled from 'styled-components'
import { ListGroup, Collapse, Row } from 'react-bootstrap'
import { FaAngleRight, FaAngleDown } from 'react-icons/fa'
import Link from '../Link'
import GlobalContext from '../../context/GlobalContext'
import DarkModeIcon from '../../assets/image/svg/ic_moon_solid.inline.svg'
import LightModeIcon from '../../assets/image/svg/ic_sun.inline.svg'
import { useSelector } from 'react-redux'
import NewTabIcon from '../../assets/image/svg/ic_newtab16.inline.svg'
import { useTranslation } from 'react-i18next'

const DarkModeSwitch = loadable(() => import('../Core/DarkModeSwitch'))
const CurrSelector = loadable(() => import('../Header/CurrSelector'))
// const LangSelector = loadable(() => import('../Header/LangSelector'))

const defaultMenuItems = [
  { name: 'home', label: 'Home' },
  {
    name: 'billing',
    label: 'Billing',
    items: [
      { name: 'statements', label: 'Statements' },
      { name: 'reports', label: 'Reports' },
    ],
  },
  {
    name: 'settings',
    label: 'Settings',
    items: [
      { name: 'profile', label: 'Profile' },
      { name: 'insurance', label: 'Insurance' },
      {
        name: 'notifications',
        label: 'Notifications',
        items: [
          { name: 'email', label: 'Email' },
          {
            name: 'desktop',
            label: 'Desktop',
            items: [
              { name: 'schedule', label: 'Schedule' },
              { name: 'frequency', label: 'Frequency' },
            ],
          },
          { name: 'sms', label: 'SMS' },
        ],
      },
    ],
  },
]

// const ImgStyled = styled.img`
//     filter: invert(50%);
//     margin-bottom: 2px;
//     margin-left: 4px;
//     width: 16px;
//     height: 16px;
//   `;

const MenuItem = ({
  label,
  isExternal = false,
  name,
  items,
  depthStep = 20,
  depth = 0,
  // color,
  userDarkMode,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const hasSubItems = Array.isArray(items)
  const { t } = useTranslation()
  const gContext = useContext(GlobalContext)

  let textColor = userDarkMode ? '#FAFAFA' : '#2B2F36'

  return (
    <>
      {name === 'darkMode' ? (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
            background-color: transparent !important;
          `}
        >
          <Row>
            <LightModeIcon
              width="16"
              height="16"
              aria-hidden="true"
              className="ml-3 mt-2"
              style={{ filter: userDarkMode ? 'invert(80%)' : 'invert(20%)' }}
            />
            <DarkModeSwitch comingSoon={false} />
            <DarkModeIcon
              width="16"
              height="16"
              aria-hidden="true"
              className="mt-2"
              style={{ filter: userDarkMode ? 'invert(80%)' : 'invert(20%)' }}
            />
          </Row>
        </ListGroup.Item>
      ) : null}
      {name === 'darkModeComingSoon' ? (
          <Row>
            <LightModeIcon
              width="16"
              height="16"
              aria-hidden="true"
              className="ml-3 mt-2"
              style={{ filter: userDarkMode ? 'invert(80%)' : 'invert(20%)' }}
            />
            <DarkModeSwitch comingSoon={true} />
            <DarkModeIcon
              width="16"
              height="16"
              aria-hidden="true"
              className="mt-2"
              style={{ filter: userDarkMode ? 'invert(80%)' : 'invert(20%)' }}
            />
          </Row>
      ) : null}
      {name === 'currency' ? (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            padding-top: 0.25rem !important;
            padding-bottom: 0.25rem !important;
            background-color: transparent !important;
            color: ${textColor};
          `}
        >
          <CurrSelector white={userDarkMode} />
        </ListGroup.Item>
      ) : null}
      {/* {name === 'language' ? (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            padding-top: 0.25rem !important;
            padding-bottom: 0.25rem !important;
            background-color: transparent !important;
            color: ${textColor};
          `}
        >
          <LangSelector white={userDarkMode} />
        </ListGroup.Item>
      ) : null} */}
      {hasSubItems ? (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            background-color: transparent !important;
            cursor: pointer;
            color: ${textColor};
          `}
          onClick={() => setOpen(!open)}
          className="d-flex align-items-center justify-content-between"
        >
          <span style={{ color: textColor }}>{label}</span>
          <span style={{ color: textColor }}>
            {open ? <FaAngleDown /> : <FaAngleRight />}
          </span>
        </ListGroup.Item>
      ) : name !== 'currency' && name !== 'darkMode' && name !== 'language' ? (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            background-color: transparent !important;
            color: ${textColor};
          `}
        >
          {isExternal ? (
            <a
              aria-label={label}
              href={`${name}`}
              // target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas()
                }
              }}
            >
              <span style={{ color: textColor }}>{label}</span>
              <NewTabIcon
                style={{
                  filter: userDarkMode ? 'invert(40%)' : 'invert(50%)',
                  marginBottom: '2px',
                  marginLeft: '4px',
                  width: '16px',
                  height: '16px',
                }}
                aria-label={t('newTab')}
                role="img"
              />{' '}
            </a>
          ) : (
            <Link
              aria-label={label}
              to={`/${name}/`}
              onClick={() => {
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas()
                }
              }}
            >
              <span style={{ color: textColor }}>{label}</span>
            </Link>
          )}
        </ListGroup.Item>
      ) : null}

      {hasSubItems ? (
        <Collapse in={open}>
          <ListGroup>
            {items.map(subItem => {
              return (
                <ListGroup.Item
                  key={subItem.name}
                  {...rest}
                  css={`
                    padding-left: ${depth + 1 * depthStep}px !important;
                    background-color: transparent !important;
                    color: ${textColor};
                  `}
                >
                  {subItem.name.includes('https') ? (
                    <a
                      aria-label={subItem.label}
                      href={`${subItem.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        if (gContext.visibleOffCanvas) {
                          gContext.toggleOffCanvas()
                        }
                      }}
                    >
                      <span style={{ color: textColor }}>{subItem.label}</span>
                      <NewTabIcon
                        style={{
                          filter: userDarkMode ? 'invert(40%)' : 'invert(50%)',
                          marginBottom: '2px',
                          marginLeft: '4px',
                          width: '16px',
                          height: '16px',
                        }}
                        aria-label={t('newTab')}
                        role="img"
                      />{' '}
                    </a>
                  ) : (
                    <Link
                      aria-label={subItem.label}
                      to={`/${subItem.name}/`}
                      {...subItem}
                      onClick={() => {
                        if (gContext.visibleOffCanvas) {
                          gContext.toggleOffCanvas()
                        }
                      }}
                    >
                      <span style={{ color: textColor }}>{subItem.label}</span>
                    </Link>
                  )}
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </Collapse>
      ) : null}
    </>
  )
}

const NestedMenuContainer = styled.div`
  background-color: ${props => (props.userDarkMode ? 'dark' : 'white')};
  a {
    color: dark;
    transition: all 0.3s ease-out;
    &:hover,
    &:active {
      color: dark;
      text-decoration: none;
    }
  }

  .list-group-item {
    color: dark;
    background-color: transparent !important!;
    & + .collapse:not(.show) {
      .list-group-item {
        border: none !important;
        border-width: 0 !important;
        background-color: transparent !important!;
      }
    }
    & + .collapse.show {
      .list-group-item {
        &:first-child {
          border-top: none !important;
        }
      }
    }
  }
  .collapse + .list-group-item {
    border-top-width: 0;
  }
  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 1px;
  }
`

const NestedMenu = ({ menuItems = defaultMenuItems, comingSoon, ...rest }) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  return (
    <NestedMenuContainer userDarkMode={comingSoon === true ? userDarkModeSelector : userDarkMode} {...rest}>
      <ListGroup variant="flush">
        {menuItems.map((menuItem, index) => (
          <MenuItem
            userDarkMode={comingSoon === true ? userDarkModeSelector : userDarkMode}
            // color={userDarkMode ? "#FAFAFA" : "dark"}
            key={`${menuItem.name}${index}`}
            depthStep={20}
            depth={0}
            {...menuItem}
          />
        ))}
      </ListGroup>
    </NestedMenuContainer>
  )
}

export default NestedMenu
